//
//
//
//
//
//
//
//
//
//

export default {
    props:["banner"],
    data(){
        return {
            
        }
    },
    components:{

    }
}

<template>
  <div>
    <!-- 公共头部 -->
    <Header :title="title"></Header>
    <!--  内页轮播图 -->
    <Otherban :banner="banner" v-show="bannerStatus == 1"></Otherban>

    <ul class="dataUpload">
      <li class="item" v-for="(item, index) in list" :key="index">
        <div class="pic">
          <img class="img" :src="item.img" />
        </div>
        <div class="content">
          <h2 class="title">{{ item.title }}</h2>
          <div class="info">已学习 {{ item.yks }} / {{ item.zks }} 课时</div>
          <div class="jindu-box">
            <span
              class="jindu"
              :style="{ width: (item.yks / item.zks) * 100 + '%' }"
            ></span>
          </div>
          <div class="time">{{ item.start }}-{{ item.end }}</div>
          <div class="button_group">
            <div
              class="btn btn1"
              v-if="item.classes_status == 1"
              :class="{ normal: item.classes_status == 1 }"
            >
              正常
            </div>
            <div
              class="btn btn1"
              v-else-if="item.classes_status == -1"
              :class="{ closed: item.classes_status == -1 }"
            >
              班级关闭
            </div>
            <div
              class="btn btn1"
              v-else-if="item.classes_status == -2"
              :class="{ nostarted: item.classes_status == -2 }"
            >
              未开班
            </div>
            <div
              class="btn btn1"
              v-else
              :class="{ end: item.classes_status == -3 }"
            >
              已结班
            </div>
            <div
              class="masks"
              :class="{ maskes: item.classes_status !== 1 }"
              @click="stopStu"
            ></div>
            <router-link
              :to="'/learnCenter/List?id='+item.id"
              class="btn btn3"
             
              >进入学习</router-link
            >
            <router-link
              :to="'/learnCenter/prove?id=' + item.id"
              class="btn btn1"
              >学习证明</router-link
            >
          </div>
        </div>
      </li>
    </ul>
    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
import Otherban from "@/components/otherbanner/index.vue";

export default {
  components: {
    Header,
    Footer,
    Otherban,
  },

  data() {
    return {
      title: "学习中心",
      list: [],
      classStatus: 0,
      banner: "",
      bannerStatus: "",
      classData: [
        {
          title: "正常",
          state: 1,
        },
        {
          title: "班级关闭",
          state: -1,
        },
        {
          title: "未开班",
          state: -2,
        },
        {
          title: "已结班",
          state: -3,
        },
      ],
      states: "",
    };
  },
  computed: {
    uid() {
      return this.$store.state.uid;
    },
  },
  activated() {
    this.getUserInfo();
  },
  deactivated() {
    this.list = [];
  },
  created() {
    this.getbanner();
  },
  methods: {
    // 获取项目列表
    async getList() {
      var uid = this.uid;
      var data = {
        uid,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/study/course", {
        params: data,
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "学习中心 课程列表：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        console.log(res.data);
        var list = res.data;
        for (var i = 0; i < list.length; i++) {
          list[i]["start"] = this.utils.js_date_time(list[i]["start"], 3);
          list[i]["end"] = this.utils.js_date_time(list[i]["end"], 3);
          if (list[i]["img"] && list[i]["img"].substring(0, 4) != "http") {
            list[i]["img"] = this.$store.state.http + list[i]["img"];
          }
        }
        this.list = list;
      } else {
        this.list = [];
      }
    },
    async getUserInfo() {
      if (!this.uid) {
        const confirmResult = await this.$messageBox
          .confirm("您当前未登录，请登录后再进行操作！", "登录提醒")
          .catch((error) => error);
        if (confirmResult != "confirm") {
          		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
          return;
        }
       		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
      } else {
        this.getList();
      }
    },

    stopStu() {
      this.$messageBox
        .confirm("该班级暂时不能学习", "温馨提示")
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {});
    },
    // 获取banner图
    async getbanner() {
      // this.$toast.loading("加载中")
      // const { data:res } = await this.$http.post('/other/advert',{id:50});
      // console.log(res);
      // if(res.code==1){
      // this.bannerStatus = res.data.status
      // this.banner ='https://tanpei.ks265.net/'+res.data.img
      // }
    },

    jumpWx() {
      this.$router.push("/learnCenter/List");
    },
  },
};
</script>

<style scoped>
.dataUpload {
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  background: #ffffff;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
}
.dataUpload .item {
  padding: 0.4rem 0.3rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 0.02rem solid #e7e7e7;
}
.dataUpload .item:first-child {
  border-top: none;
}
.dataUpload .item .pic {
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.dataUpload .item .pic .img {
  max-height: 100%;
}
.dataUpload .item .content {
  width: 4.4rem;
}
.dataUpload .item .content .title {
  font-size: 0.3rem;
  color: #333333;
  margin-bottom: 0.1rem;
}
.dataUpload .item .content .info {
  font-size: 0.26rem;
  color: #ff6000;
}
.dataUpload .item .content .jindu-box {
  width: 100%;
  height: 0.08rem;
  background: #ededed;
  position: relative;
  margin: 0.15rem 0;
}
.dataUpload .item .content .jindu-box .jindu {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: #ff6000;
  transition: all 0.3s;
}
.dataUpload .item .content .time {
  font-size: 0.26rem;
  color: #969897;
  padding-left: 0.48rem;
  background: url(../../../assets/images/icon72.png) no-repeat left center;
  background-size: 0.34rem 0.29rem;
  margin-bottom: 0.2rem;
}
.dataUpload .item .content .button_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.dataUpload .item .content .button_group .btn {
  width: 1.4rem;
  height: 0.44rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.24rem;
  color: #333333;
  background: #ffffff;
  border: 0.02rem solid #333333;
  border-radius: 0.5rem;
  margin-right: 0.3rem;
  cursor: pointer;
  transition: all 0.3s;
}
.dataUpload .item .content .button_group .btn:last-child {
  margin-right: 0;
}
.dataUpload .item .content .button_group .btn1 {
  color: #ff6000;
  border: 0.02rem solid #ff6000;
}
.normal {
  border: none !important;
  color: #fff !important;
  background-color: #08852d !important;
}
.closed {
  border: none !important;
  color: #fff !important;
  background-color: #ff0000 !important;
}
.nostarted {
  border: none !important;
  color: #fff !important;
  background-color: #ff6000 !important;
}
.end {
  border: none !important;
  color: #666 !important;
  background-color: #f1f1f1 !important;
}
.hidde {
  transform: scale(0);
}

.masks {
  width: 70px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.maskes {
  z-index: 99 !important;
}
</style>